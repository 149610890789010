import axios from 'axios';
import toBase64 from 'helpers/utils/toBase64';

export const s3UploadV2 = async (uploadFiles: any[], isChat = false) => {
  const image: any = await Promise.all(uploadFiles?.map(async ({ file }) => await toBase64(file)));
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = isChat ? process.env.NEXT_PUBLIC_AC_UPLOAD_URL || '' : process.env.NEXT_PUBLIC_S3_FILE_UPLOAD_URL || '';
  const res = await axios.post(url, { image }, { headers });

  return {
    data: res?.data.signed_url,
    statusCode: 200,
    status: res?.data.body,
  };
};
