import { FormikValues, useField, useFormikContext } from 'formik';
import { Typography } from 'components/atoms/typography';
import {
  TextAreaAlertIcon,
  TextAreaContainer,
  TextAreaInnerContainer,
  TextAreaInput,
  TextAreaMessageContainer,
} from './styles';
export interface ITextAreaProps {
  maxLength?: number;
  name: string;
  rows?: number;
  placeholder?: string;
  showLimit?: boolean;
  className?: string;
  required?: boolean;
}

export const TextArea = ({
  name,
  maxLength = 500,
  placeholder = 'Type your message here',
  rows,
  showLimit = true,
  className = '',
  required = false,
}: ITextAreaProps) => {
  const [field] = useField(name);
  const { errors, touched, values } = useFormikContext();
  const invalid = errors[name] && touched[name];

  return (
    <TextAreaContainer className={className}>
      <TextAreaInnerContainer>
        <TextAreaInput maxLength={maxLength} placeholder={placeholder} {...field} rows={rows} aria-label={name} />
        {showLimit && (
          <Typography variant="body-small" className="text-end !text-grey-400" data-testid="character_count_textarea">
            {`${(values as FormikValues)[name].length} of ${maxLength} characters`}
          </Typography>
        )}
      </TextAreaInnerContainer>
      {required && invalid && (
        <TextAreaMessageContainer>
          <TextAreaAlertIcon />
          <Typography data-testid="rise-form-signup-label" variant="body-small-bold" className="!text-grey-500">
            {errors[name]}
          </Typography>
        </TextAreaMessageContainer>
      )}
    </TextAreaContainer>
  );
};
