import tw from 'tailwind-styled-components';

export const FilepickerContainer = tw.div<{ $isFile?: boolean }>`
    max-md:flex-wrap
    sm:flex
    md:flex-row 
    items-start 
    gap-4
    ${({ $isFile }) => ($isFile ? ' sm:flex-row' : ' sm:flex-col')}
`;
export const FilePickerInput = tw.input`
    flex
`;

export const FileLabel = tw.div`
    flex
    bg-grey-100 
    pointer-events-none 
    rounded-[32px]
`;

export const DeleteButton = tw.div`
    my-auto 
    flex
`;
