import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContactSuccessProps } from 'helpers/types/forms';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import * as screenSizes from 'helpers/utils/screensizes';
import { SuccessBannerContainer, SuccessBannerContent } from './style';

export const SuccessBanner = ({ description, image, mobileImage, title }: IContactSuccessProps) => {
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const bannerImage = isBiggerThanMobile ? image : mobileImage;
  return (
    <SuccessBannerContainer>
      <Image
        data-testid="form_success_banner_img"
        alt="contactus-success"
        loading="lazy"
        src={bannerImage.url}
        loader={(options) => options.src}
        height={isBiggerThanMobile ? 123 : 130}
        width={isBiggerThanMobile ? 550 : 255}
      />
      <SuccessBannerContent>
        <Typography variant="h4" data-testid="form_success_banner_title">
          {title}
        </Typography>
        <ContentfulRichText
          data={description}
          renderNodeOptions={{
            [BLOCKS.PARAGRAPH]: (node, children) => (
              <Typography variant="body-small" className="text-center" data-testid="form_success_banner_description">
                {children}
              </Typography>
            ),
          }}
        />
      </SuccessBannerContent>
      <div>
        {/* TO DO: Href needs to upodate to redirect product listing page based on geo-location */}
        <CommonButton
          size="sm"
          button-type="primary"
          color="green"
          as="a"
          href={getPathMenuFiltersLink('?path=menu')}
          data-testid="form_success_banner_button"
        >
          Continue shoping
        </CommonButton>
      </div>
    </SuccessBannerContainer>
  );
};
