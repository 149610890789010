import { useEffect, useRef, useState } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { getSizefromBytes } from 'helpers/utils/calculate-file-size';
import AlertIcon from 'public/icons/figma/alert.svg';
import DeletBinIcon from 'public/icons/figma/delete-bin.svg';
import UploadIcon from 'public/icons/figma/upload.svg';
import { DeleteButton, FileLabel, FilePickerInput, FilepickerContainer } from './style';

interface IProps {
  label: string;
  fieldName: string;
  values?: any;
  error?: any;
  formType?: string;
  onChange(event): void;
  onDelete?(): void;
}

export const FilePickerText = (maxAllowedSize = 50) => {
  return {
    VA: {
      errorMsg: `Your file upload exceeds the maximum size. Please reduce the file size.`,
      description: `Max file size is ${maxAllowedSize} Mb. Supported file types are .pdf, .jpg, and .png.`,
    },
    Default: {
      errorMsg: `Error uploading file. Max file size is ${maxAllowedSize} Mb. Supported file types are .jpg, .jpeg, .pdf and .png`,
      description: `*Optional. Max file size is ${maxAllowedSize} Mb. Supported file types are .jpg, .png and .pdf`,
    },
  };
};
const FilePicker = ({ label, fieldName, values, error, formType = 'Default', onChange, onDelete }: IProps) => {
  const fileInput: any = useRef();
  let initFile = {
    size: '',
    name: '',
    type: '',
  };
  if (values && values?.file) {
    const { size, name, type } = values?.file;
    initFile = { size, name, type };
  }
  const [file, setFile] = useState({ ...initFile });
  const [fileError, setFileError] = useState(error);
  useEffect(() => {
    setFileError(error);
  }, [error]);
  const onFileUpload = async (event) => {
    const target = event.target;
    if (target.files && target.files[0]) {
      const { size, name, type } = target.files[0];
      // Change first operand(multiplier) for your needs
      const maxAllowedSize = 50 * 1024 * 1024; // Maximum allowed size 50MB
      const currentSize: string = getSizefromBytes(size);
      if (size > maxAllowedSize) {
        setFileError(FilePickerText()[formType].errorMsg);
      } else {
        setFile({ size: currentSize, name, type });
        onChange({ file: target.files[0] });
      }
    }
  };

  const onDeleteFile = async () => {
    setFile({
      size: '',
      name: '',
      type: '',
    });
    onChange('');
    if (fileError) setFileError('');
    if (onDelete) onDelete();
  };
  return (
    <FilepickerContainer $isFile={file.name ? true : false}>
      {!file.name && (
        <>
          <IconWithTextButton
            data-testid="file-upload-button"
            button-type="secondary"
            color="green"
            iconPosition="before"
            icon={<UploadIcon data-testid="upload-icon" />}
            onClick={(e) => {
              fileInput.current.click();
              e.preventDefault();
            }}
            disabled={file.name ? true : false}
          >
            {label}
          </IconWithTextButton>
          <FilePickerInput
            data-testid={'file-input-' + fieldName}
            type="file"
            name={fieldName}
            ref={fileInput}
            accept=".jpg, .jpeg, .png, .pdf"
            style={{ display: 'none' }}
            onChange={(event) => onFileUpload(event)}
          />
          {!fileError && (
            <Typography
              variant="body-small"
              className="max-md:max-w-full my-auto shrink grow basis-auto self-center !text-grey-400"
            >
              {FilePickerText()[formType].description}
            </Typography>
          )}
          {fileError && (
            <span className="flex items-center self-center">
              <AlertIcon className="text-error" />
              <Typography
                variant="body-small-bold"
                className="max-md:max-w-full my-auto ml-2 shrink grow basis-auto self-center !text-grey-500"
              >
                {fileError}
              </Typography>
            </span>
          )}
        </>
      )}
      {file.name && (
        <>
          <FileLabel data-testid="file-name-size">
            <CommonButton button-type="secondary" color="green">
              {`${file.name} (${file.size})`}
            </CommonButton>
          </FileLabel>
          <DeleteButton>
            <IconWithTextButton
              data-testid="delete-button"
              button-type="tertiary"
              color="green"
              iconPosition="before"
              icon={<DeletBinIcon />}
              aria-label="Delete button"
              onClick={() => onDeleteFile()}
            >
              Delete file
            </IconWithTextButton>
          </DeleteButton>
        </>
      )}
    </FilepickerContainer>
  );
};

export default FilePicker;
