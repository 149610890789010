import tw from 'tailwind-styled-components';
import Alert from 'public/icons/figma/alert.svg';

export const TextAreaContainer = tw.div`
    flex
    flex-col
    items-start
    gap-2
    self-stretch
`;

export const TextAreaInnerContainer = tw.div`
    w-full
    rounded-md
    border
    border-grey-480
    p-5
`;

export const TextAreaInput = tw.textarea`
    w-full
    border-0
    focus:ring-0
    p-0
    placeholder:after:content-['*']
    placeholder:after:text-error
`;

export const TextAreaMessageContainer = tw.div`
    flex
    flex-row
    items-start
    gap-2
    self-stretch
`;

export const TextAreaFlex = tw.div`
    !grow
    !shrink-0
    !basis-0
`;

export const TextAreaAlertIcon = tw(Alert)`
    text-error
    h-6
    w-6
    min-w-[24px]
`;
