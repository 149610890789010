import { FormikProps, useField, useFormikContext } from 'formik';
import InputTextField, { InputTextFieldProps } from 'components/atoms/inputs/text-field';
interface FormikTextFieldInputProps {
  fieldName: string;
  limit?: number;
}

export const FormikTextFieldInput = ({
  fieldName,
  required = false,
  label,
  limit,
  onChange,
  type,
  disabled,
}: FormikTextFieldInputProps & InputTextFieldProps) => {
  const [field] = useField(fieldName);
  const { errors, touched, handleChange, setFieldTouched } = useFormikContext();
  const invalid = errors[fieldName] && touched[fieldName];

  return (
    <InputTextField
      label={label}
      disabled={disabled}
      required={required}
      maxLength={limit}
      aria-label={label}
      aria-invalid={invalid ? true : false}
      aria-describedby={invalid ? `${fieldName}_error` : undefined}
      aria-required={required ? 'true' : undefined}
      type={type}
      {...field}
      onChange={(e) => {
        onChange?.(e);
        handleChange?.(e);
        if (!touched[fieldName]) setFieldTouched?.(fieldName, true);
      }}
      error={invalid ? true : false}
      message={errors[fieldName]}
    />
  );
};

export const FirstNameInput = (props: FormikProps<InputTextFieldProps> & InputTextFieldProps) => {
  return <FormikTextFieldInput fieldName="firstName" label="First Name" type="text" {...props} limit={60} />;
};

export const MiddleNameInput = (props: FormikProps<InputTextFieldProps> & InputTextFieldProps) => {
  return <FormikTextFieldInput fieldName="middleName" label="Middle Name" type="text" {...props} limit={60} />;
};

export const LastNameInput = (props: FormikProps<InputTextFieldProps> & InputTextFieldProps) => {
  return <FormikTextFieldInput fieldName="lastName" label="Last Name" type="text" {...props} limit={60} />;
};

export const DobInput = (props: FormikProps<InputTextFieldProps> & InputTextFieldProps) => {
  return <FormikTextFieldInput fieldName="dob" label="Date of birth" type="date" {...props} />;
};

export const EmailInput = (props: FormikProps<InputTextFieldProps> & InputTextFieldProps) => {
  // To prevent browser error message. Type should be text instead of email. We want to show custom message error vs browser error message.
  return <FormikTextFieldInput fieldName="email" label="Email" type="text" {...props} limit={100} />;
};
