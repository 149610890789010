const toBase64 = (file: File | any): Promise<string> => {
  if (!file) return new Promise((resolve) => resolve(''));
  if (typeof file === 'string' && file?.includes?.(';base64')) return new Promise((resolve) => resolve(file));
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });
};

export default toBase64;
