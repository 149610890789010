import { useEffect, useRef } from 'react';
import Image from 'next/image';
import { RadioDefault, RadioDefaultProps } from 'components/atoms/inputs/radio/radio-list-item';
import PlantIcon from 'public/icons/figma/plant-icon.svg';
import RadioCheckIcon from 'public/icons/figma/radio-check.svg';

interface RadioLabelProps {
  label: string | any;
  Icon: string;
  isChecked: boolean;
  iconUrl?: string;
}

const ContainerStyle = `
  flex
  justify-end
  shrink-0
  bg-grey-100
  rounded-md
  flex-row-reverse
  items-center
  cursor-pointer

  [&_svg]:shrink-0

  min-w-[350px]
  sm:h-[64px]
  sm:p-[20px]
  sm:gap-5

  w-full
  md:h-[80px]
  md:p-[28px_24px]
  md:gap-6
`;

const labelStyle = `
  font-arial
  not-italic
  text-leaf
  flex
  [flex:1_0_0]
  cursor-pointer
  w-full
  shrink-0
  text-sm
  leading-4
  tracking-[0.14px]
  gap-5

  lg:w-auto
  lg:text-base
  lg:leading-5
  lg:tracking-[0.16px]
  lg:gap-6
  [&_svg_path]:fill-resin
`;
const CustomLabel = ({ label = '', Icon = PlantIcon, isChecked, iconUrl }: RadioLabelProps) => {
  return (
    <>
      {iconUrl ? (
        <Image alt={label + '-icon'} height={24} width={24} loader={(options) => options.src} src={iconUrl} />
      ) : (
        <Icon />
      )}{' '}
      <span
        className={
          'w-full shrink-0 text-[14px] font-bold leading-4 lg:text-[16px] lg:leading-5 ' + isChecked ? '' : 'font-bold'
        }
      >
        {label}
      </span>
    </>
  );
};
const BG_DEFAULT = 'bg-grey-100';
const BG_ACTIVE =
  'bg-leaf [&_span]:text-white [&_label:nth-of-type(2)_svg_path]:fill-white [&_label:nth-of-type(2)_svg_path]:[fill-opacity:0.6]';

export const RadioCheck = ({
  value = '',
  checked = false,
  label,
  className = '',
  onClick,
  name,
  CustomIcon,
  iconUrl,
}: RadioDefaultProps) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current.className = ContainerStyle;

    ref.current.childNodes[1].className = labelStyle;
  }, []);

  useEffect(() => {
    if (checked) {
      ref.current.className = ref.current?.className?.replaceAll?.(BG_DEFAULT, BG_ACTIVE);
    } else {
      ref.current.className = ref.current?.className?.replaceAll?.(BG_ACTIVE, BG_DEFAULT);
    }
  }, [checked]);

  return (
    <RadioDefault
      value={value}
      ref={ref}
      checked={checked}
      name={name}
      CustomIcon={RadioCheckIcon}
      label={<CustomLabel label={label} Icon={CustomIcon as string} isChecked={checked} iconUrl={iconUrl} />}
      className={className}
      onClick={onClick}
      data-testid={`radio_${name}_${label}`}
    />
  );
};
