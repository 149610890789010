import tw from 'tailwind-styled-components';
import CheckIconFigma from 'public/icons/figma/check.svg';
import CloseIconFigma from 'public/icons/figma/close-small.svg';

export const MainContainer = tw.div`
    flex
    flex-col
    gap-10
    w-full
    xl:max-w-[1920px]
    xl:flex-row
    xl:gap-1
`;

export const BannerContainer = tw.div`
    flex
    flex-row
    gap-6
    h-auto
    w-full
    px-5
    pt-6
    pb-8
    bg-kief
    xl:max-w-[492px]
    xl:max-h-[824px]
    xl:min-w-[392px]
    xl:min-h-[824px]
    xl:pt-[104px]
    xl:gap-[68px]
    xl:flex-col

`;

export const LogoContainer = tw.div`
    xl:mx-auto
    xl:my-0   
    my-auto
`;

export const BannerContent = tw.div`
    flex 
    flex-col
    mx-auto
    xl:items-center
    w-full
    md:max-w-[386px]
`;

export const BannerSubTitle = tw.div`
    flex
    max-w-[237px] 
    flex-col 
    md:gap-6 
    md:text-center
`;

export const RightContainer = tw.div`
    flex
    flex-col
    w-screen
    md:max-h-[824px]
    overflow-y-auto
    overflow-x-auto
`;
export const UpperContainer = tw.div`
    flex
    md:pl-44
    md:pt-14
    px-5

`;
export const CenterContainer = tw.div<{ $footer?: boolean }>`
    flex
    flex-col 
    items-start 
    w-full
    md:min-w-[1040px]

`;
export const Content = tw.div`
    flex 
    mt-12 
    w-full 
    flex-col 
    items-start 
    md:gap-16 
    self-center 
    md:mt-10
    mt-8
    gap-9
`;

export const ListContainer = tw.div`
    w-full
    grid
    grid-cols-1
    md:grid-cols-2
    md:gap-5
    gap-3
`;

export const FooterContainer = tw.div`
    flex
    max-md:max-w-full
    md:mt-10 
    mt-14
    md:border-t 
    md:border-solid 
    md:border-t-grey-200
    my-10
    md:pl-44
    px-5
`;

export const FooterContent = tw.div`
    flex
    w-full
    justify-between
`;

export const FooterButton = tw.div<{ $marginLeft?: boolean }>`
    md:py-8
    py-4
    my-auto
    ${(p) => (p.$marginLeft ? 'md:ml-auto' : '')}
`;
export const FooterRightButton = tw(FooterButton)`
    flex
    justify-end
    w-full
    md:w-auto
`;
export const RecaptchaContainer = tw.div`
    md:py-[26px]
    md:ml-auto
`;

export const SuccessBannerContainer = tw.div`
    flex 
    w-full 
    flex-col 
    items-center 
    gap-10
    m-auto
    mb-[120px]
    mt-10
    lg:mt-5
    lg:mb-[60px]
    xl:my-auto
`;

export const SuccessBannerContent = tw.div`
    flex 
    flex-col 
    items-center 
    gap-4 
    md:gap-5
`;

export const StepTwoFormContainer = tw.div`
    flex 
    w-full 
    flex-col 
    gap-8
`;

export const StepThreeFormContainer = tw.div`
    flex 
    flex-wrap 
    sm:flex-col 
    sm:gap-8 
    md:flex-row
    grid
    sm:grid-cols-1
    md:grid-cols-3
    lg:grid-cols-2
    md:gap-10
    w-full
`;

export const FileDeleteSuccess = tw.div`
    flex 
    h-12 
    w-full 
    flex-shrink-0  
    bg-leaf 
    text-white
`;

export const CheckIcon = tw(CheckIconFigma)`
    my-auto 
    mr-3 
    text-kief
`;

export const CloseIcon = tw(CloseIconFigma)`
    my-auto 
    ml-auto 
    mr-10
    text-white
`;

export const Center = tw.div`
    m-auto 
    flex
`;

export const ErrorContainer = tw.div`
    mb-3
`;
