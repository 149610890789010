import React, { createRef } from 'react';
import { useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import HandleError from '../../molecules/form-fields/handle-error';
interface RecaptchaProps {
  fieldName?: string;
}
const recaptchaRef: any = createRef();
const SITE_KEY = process.env.NEXT_PUBLIC_RC_SITEKEY;
const ReCaptcha: React.FC<RecaptchaProps> = ({ fieldName = 'reCaptchaToken' }) => {
  const { setFieldValue, errors, touched } = useFormikContext();

  return (
    <div data-testid="form_input_recaptcha">
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={SITE_KEY}
        onChange={(token) => {
          setFieldValue(fieldName, token);
        }}
        onExpired={() => {
          setFieldValue(fieldName, '');
        }}
      />
      <HandleError name={fieldName} error={errors[fieldName]} touched={touched[fieldName]} />
    </div>
  );
};
export default ReCaptcha;
