import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import InputTextField from 'components/atoms/inputs/text-field';

type TProps = {
  fieldName: string;
  required?: boolean;
  label?: string;
  customOnChange?: (e) => void;
};

const PhoneNumber: React.FC<TProps> = ({ label, fieldName, required = false, customOnChange }: TProps) => {
  const [field] = useField(fieldName);
  const { errors, touched, setFieldTouched } = useFormikContext();

  const invalid = errors[fieldName] && touched[fieldName];
  const [showPlaceHolder, setShowPlaceHolder] = useState(false);

  return (
    <NumberFormat
      aria-label={label}
      {...field}
      customInput={InputTextField}
      error={invalid}
      message={errors[fieldName]}
      onFocus={() => {
        setShowPlaceHolder(true);
        setFieldTouched(fieldName, true);
      }}
      onBlur={(e) => {
        field.onBlur(e);
        setShowPlaceHolder(false);
      }}
      onValueChange={(number) => {
        field.onChange({
          target: {
            name: field.name,
            value: number.formattedValue.replace('X', ''),
          },
        });
      }}
      onChange={(e) => {
        if (customOnChange) customOnChange(e);
      }}
      format="### ### ####"
      label={label ? label : 'Enter mobile number'}
      placeholder={showPlaceHolder ? '000 000 0000' : ''}
      mask=""
      aria-invalid={invalid ? true : false}
      aria-describedby={invalid ? `${fieldName}_error` : undefined}
      aria-required={required ? 'true' : undefined}
      required={required}
    />
  );
};

export default PhoneNumber;
