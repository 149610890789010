import { forwardRef, useEffect, useRef } from 'react';
import { Radio, RadioProps } from '@material-tailwind/react/components/Radio';
import RadioDefaultCheckedIcon from 'public/icons/figma/radio-filled-default.svg';
export interface RadioDefaultProps {
  checked?: boolean;
  label?: string | any;
  CustomIcon?: string;
  className?: string;
  color?: any;
  ref?: any;
  onClick?: (event: any) => any;
  containerProps?: any;
  name: string;
  value?: string;
  'data-testid'?: string;
  iconUrl?: string;
  defaultChecked?: boolean;
}

export const RadioDefault = forwardRef(
  (
    {
      value,
      checked,
      label = 'label',
      CustomIcon = RadioDefaultCheckedIcon,
      className = '',
      color = 'green',
      onClick,
      containerProps,
      name,
      defaultChecked,
      'data-testid': testId,
    }: RadioProps & { CustomIcon?: any; 'data-testid'?: string },
    ref: any,
  ) => {
    return (
      <Radio
        value={value}
        ref={ref}
        color={color}
        className={'border-transparent bg-[url("/icons/figma/radio-empty-icon.svg")] ' + className}
        crossOrigin=""
        name={name}
        label={label}
        icon={<CustomIcon />}
        defaultChecked={defaultChecked}
        checked={checked}
        containerProps={containerProps}
        onChange={(e: any) => {
          const label = e.target?.labels?.[1].childNodes?.[0]?.nodeValue || e.target?.labels[1]?.innerText;
          if (onClick) {
            onClick(label);
          }
        }}
        aria-checked={checked}
        aria-label={`Select radio button ${name}`}
        data-testid={testId}
      />
    );
  },
);
RadioDefault.displayName = 'RadioDefault';

const BasicContainerStyle = `
  flex
  items-center
  cursor-pointer

  min-w-[345px]
  w-full
  py-5
  px-0
  sm:gap-2.5
  sm:flex-row-reverse

  md:sm:[flex-direction:unset]
  md:w-[676px]
  md:gap-3

`;

export const RadioBasic = ({ checked, label, className, onClick, name }: RadioDefaultProps) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current.className = BasicContainerStyle + className;
    ref.current.childNodes[1].className = `grow flex-shrink-0 basis-0 text-sm leading-4 tracking-[0.14px] peer-checked:text-red-100 cursor-pointer ${
      checked ? 'text-grey-500' : 'text-grey-400'
    }`;
  }, [checked]);

  return (
    <RadioDefault
      ref={ref}
      checked={checked}
      label={label}
      name={name}
      className={' ' + className}
      onClick={onClick}
      data-testid={`radio_${name}_${label}`}
    />
  );
};
