import * as yup from 'yup';
import ZipCodes from 'helpers/constants/zipcodes.json';

const alphaAndSpecialCharacterRegex = /^[A-Z .-]*$/i;
export const firstName = yup
  .string()
  .matches(alphaAndSpecialCharacterRegex, 'Only letters are allowed')
  .max(60, 'First Name is too long')
  .required('This field is required.');

export const middleName = yup
  .string()
  .matches(alphaAndSpecialCharacterRegex, 'Only letters are allowed')
  .max(60, 'Last Name is too long')
  .required('This field is required.');

export const lastName = yup
  .string()
  .matches(alphaAndSpecialCharacterRegex, 'Only letters are allowed')
  .max(60, 'Last Name is too long')
  .required('This field is required.');

export const email = yup
  .string()
  .required('This field is required.')
  .test('customerEmail', 'Invalid email', (customerEmail) => {
    const tester =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!customerEmail) return false;
    if (!tester.test(customerEmail.toLowerCase())) return false;
    return true;
  })
  .max(100, 'Email is too long');

const phoneRegExp = /[2-9]{1}[0-9]{2} [2-9]{1}[0-9]{2} [0-9]{4}/;
export const phoneNumber = yup
  .string()
  .required('This field is required.')
  .length(12, 'Please enter a valid mobile number length')
  .matches(phoneRegExp, 'Please enter a valid mobile number');

export const state = yup.string().required('This field is required.');
export const dispensary = yup.string().required('This field is required.');
export const zip = yup
  .string()
  .length(5, 'Entered zipcode is invalid')
  .test('zipCode', 'Entered zipcode is invalid', (zipcode) => {
    const isValid = ZipCodes[zipcode as string] ? true : false;
    return isValid;
  });
export const zipRequired = zip.required('This field is required.');

export const date = yup.string().test('date', 'Invalid date', (value) => {
  if (!value) return true;
  if (value && value?.length === 10) {
    const date = new Date(value);
    const [month, day, year] = value.split('/').map((part) => parseInt(part, 10));
    const isValid =
      !isNaN(date.getTime()) && date.getMonth() + 1 === month && date.getDate() === day && date.getFullYear() === year;
    if (!isValid) {
      return false;
    }

    const daysInMonth = new Date(year, month, 0).getDate();
    const previousYear = new Date().getFullYear() - 100;
    const nextYear = new Date().getFullYear() + 100;
    if (day > daysInMonth || previousYear > year || year > nextYear) {
      return false;
    }
    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };
    if (day > 28 && month === 2 && !isLeapYear(year)) {
      return false;
    }
    return true;
  } else {
    return false;
  }
});

export const dateRequired = date.required('This field is required.');

export const dob = date.test('age', 'You must be at least 21 years old', (value) => {
  if (!value) return true;
  const dobDate = new Date(value);
  const currentDate = new Date();
  const age = currentDate.getFullYear() - dobDate.getFullYear();
  return age >= 21;
});

export const dobRequired = dob.required('This field is required.');

export const authorise = yup
  .boolean()
  .oneOf([true], 'The authorise must be accepted.')
  .required('The authorise must be accepted.');

export const terms = yup
  .boolean()
  .oneOf([true], 'The terms and conditions must be accepted.')
  .required('The terms and conditions must be accepted.');

export const reCaptchaToken = yup.string().required('The reCAPTCHA was invalid. Go back and try it again.');
export const howDidYouFindUs = yup.array().min(1, 'This field is required.').required('This field is required.');
export const signature = yup.object().required('This field is required.');
export const fileUploadobject = yup.object().required('This field is required.');

export const stringRequired = yup.string().required('This field is required.');
export const alphaNumericStringRequired = yup
  .string()
  .required('This field is required.')
  .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed');

export const emptyString = yup.string();
export const emptyObject = yup.object();

export const CommonLoyaltyFormValidation = yup.object().shape({
  firstName,
  lastName,
  phoneNumber,
  dob: dobRequired,
  state,
  dispensary,
  authorise,
  terms,
});

export const ContactUsValidationSchemaObj = yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber,
  state,
  dispensary,
  reCaptchaToken,
  help_you_with: stringRequired,
});

export const PatientRegValidationSchemaObj = yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber,
  state,
  dispensary,
  reCaptchaToken,
  help_you_with: stringRequired,
  howDidYouFindUs,
});

export const PatientRegVAValidationSchemaObj = yup.object().shape({
  registeredWithCommonwealth: stringRequired,
  veteran: stringRequired,
  firstName,
  middleName,
  lastName,
  email,
  phoneNumber,
  state,
  dispensary,
  reCaptchaToken,
  howDidYouFindUs,
  city: stringRequired,
  zip: zipRequired,
  patientId_MedicalCard: alphaNumericStringRequired,
  expirationDate: dateRequired,
  freeConsultation: stringRequired,
  packagingPreference: stringRequired,
  virginia_ID: fileUploadobject,
  registrationCard: fileUploadobject,
  letterOfCertification: fileUploadobject,
  signatureVirginia: signature,
  date: dateRequired,

  dateOfBirth: date, // not required
});

export const PatientRegContactVAValidationSchemaObj = yup.object().shape({
  registeredWithCommonwealth: stringRequired,
  firstName,
  lastName,
  email,
  phoneNumber,
  state,
  dispensary,
  reCaptchaToken,
  howDidYouFindUs,

  veteran: emptyString,
  middleName: emptyString,
  dateOfBirth: emptyString,
  address_1: emptyString,
  address_2: emptyString,
  city: emptyString,
  zip: emptyString,
  patientId_MedicalCard: emptyString,
  expirationDate: emptyString,
  freeConsultation: emptyString,
  packagingPreference: emptyString,
  virginia_ID: emptyObject,
  registrationCard: emptyObject,
  letterOfCertification: emptyObject,
  signatureVirginia: emptyObject,
  date: emptyString,
});

export const PatientRegNYValidationSchemaObj = yup.object().shape({
  firstName,
  lastName,
  email,
  dispensary,
  phoneNumber,
  state,
  PatientsSignature: signature,
  DatePrivacyPracticesNotice: dateRequired,
  SignatureCodeOfConductAgreement: signature,
  DateCodeOfConductAgreement: dateRequired,
  nameOfTheIndividualGivingThisAuthorization: stringRequired,
  dateOfBirthAuthorization: dobRequired,
  signatureOfTheIndividualGivingThisAuthorization: signature,
  dateAuthorization: dateRequired,
  reCaptchaToken,

  zip,
});

export const LoginSchema = yup.object().shape({
  email,
});
