import React from 'react';
import tw from 'tailwind-styled-components';
import Alert from 'public/icons/figma/alert.svg';
import { Typography } from '../../atoms/typography';

type TProps = {
  name?: string;
  errorStyle?: string;
  error: string;
  touched: boolean;
};
const FieldErrorContainer = tw.div`
  flex
  flex-row
  gap-1
`;

const FieldErrorContent = tw.div`
  pt-[5px]

`;

const HandleError = ({ name = 'field', error, touched }: TProps) => {
  if (error && touched) {
    return (
      <FieldErrorContainer id={`${name}_error`} data-testid="error-handler">
        <Alert className="text-error" />
        <FieldErrorContent>
          <Typography variant="body-small-bold" className="!text-grey-500">
            {error}
          </Typography>
        </FieldErrorContent>
      </FieldErrorContainer>
    );
  }

  return null;
};

export default HandleError;
